$(function() {

	$('.ticker1, .ticker2').easyTicker({
		direction: 'up',
		easing: 'swing',
		speed: 'slow',
		interval: 5000,
		height: 'auto',
		visible: 3,
		mousePause: 0,
		controls: {
			up: '',
			down: '',
			toggle: '',
			playText: 'Play',
			stopText: 'Stop'
		}
	});


	var dirRtlFlag = false;
	if ($("html").attr('dir') == 'rtl') {
		dirRtlFlag = true;
	}

	$('.hp-team-slider').slick({
		slidesToShow: 5,
		slidesToScroll: 1,
		dots: false,
		infinite: true,
		speed: 1000,
		rtl: dirRtlFlag,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 3000,
		cssEase: 'linear',
		pauseOnHover: false,
		responsive: [{
				breakpoint: 1100,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 545,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 390,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: true,
				}
			}
		]
	});

});
