function sideForm() {
	$('.side-form').toggleClass('side-form_opened');
	$('.side-form__btn-content_close, .side-form__btn-content_open').toggleClass('side-form__btn-content_hide');
	$('.side-form__overlay').toggleClass('side-form__overlay_revealed');
}

$('.side-form__btn, .side-form__overlay').click(sideForm);

$('body').keyup(function(e) {
	if ($('.side-form_opened').length && e.which == 27) {
		sideForm();
	}


	$('body').on('submit', '.js-validate-form', function(e) {
		if (e.isDefaultPrevented()) {
			// handle the invalid form...
		} else {
			$.ajax({
				type: "POST",
				url: $('.js-validate-form').attr('action'),
				data: $('.js-validate-form').serialize(),
				dataType: 'json',
				success: function(data) {
					if (data.status == "success") {
						$('.js-validate-form .msg-success').removeClass('hidden');
						$('.js-validate-form input:not(.js-validate-form--send)').val("");

						setTimeout(function() {
							$('.js-validate-form .msg-success').addClass('hidden');
						}, 3000);
					} else {
						console.log(1);

					}
				}
			});
		}
		return false;
	});

});
